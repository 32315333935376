<template>
  <div class="innovations-content">
    <div class="main">
      <div class="main-left">
        <!-- 我的积分 -->
        <div class="left-top">
          <div class="integral">我的积分</div>
          <div class="top-main">
            <integral-list />
            <content-button :list="btns" />
          </div>
        </div>
        <!-- 看看大家的创新提案 -->
        <div class="left-bottom">
          <div class="list-title">
            <div class="title">看看大家的创新提案</div>
            <div class="refresh" @click="nextPage">
              <i class="el-icon-refresh icon"><span class="icon-text">换一批</span></i>
            </div>
          </div>
          <!-- 提案列表 -->
          <proposal-list :data="proposalList" v-if="proposalList && proposalList.length > 0" />
          <tojoy-blank :blank-data="{ type: 'notdata' }" v-else />
        </div>
      </div>
      <!-- 历史分数 -->
      <div class="main-right">
        <div class="history-grade">历史分数</div>
        <tojoy-infinite-list :data="list" :func="getList" :total="historyTotal" :size="historySize">
          <template #item="{data}">
            <history-grade :data="data" />
          </template>
        </tojoy-infinite-list>
      </div>
    </div>
  </div>
</template>

<script>
import TojoyInfiniteList from '@/components/business/infinite-list'
import ProposalList from '@/components/initiate/proposal-list'
import HistoryGrade from '../../components/initiate/history-grade-list.vue'
import IntegralList from '../../components/initiate/integral-list.vue'
import contentButton from '../../components/initiate/content-button.vue'
import { getInnovation, getScorehistorylist, getListCount } from '@/api/api_innovation'
import TojoyBlank from '@/components/business/blank/blank'
import { btns } from '@/components/initiate/state.js'
export default {
  name: 'innovations',
  components: {
    ProposalList,
    HistoryGrade,
    IntegralList,
    TojoyInfiniteList,
    contentButton,
    TojoyBlank
  },
  data() {
    return {
      btns: btns,
      list: [],
      page: 1,
      total: 0,
      size: 20,
      historySize: 20,
      historyTotal: 0,
      proposalList: []
    }
  },
  methods: {
    getCount() {
      getListCount({ innovateState: 0 })
        .then(res => {
          //待提交 toBeSubmitted,审批中 underReview
          let { toBeSubmitted, approvalPending } = res.data
          this.$set(this.btns[1], 'badge', toBeSubmitted)
          this.$set(this.btns[2], 'badge', approvalPending)
        })
        .catch(err => {
          console.log(err)
        })
    },
    getList(page) {
      let params = {
        page: page,
        size: this.historySize
      }
      getScorehistorylist(params)
        .then(res => {
          let { total, list } = res.data
          if (list && list.length > 0) {
            this.list.push(...list)
            this.historyTotal = total
          }
        })
        .catch(err => console.log(err))
    },
    /**
     * 换一批
     */
    nextPage() {
      this.page = this.page + 1
      if (this.total < this.size) {
        this.$warning('暂无更多数据')
        return false
      }
      if (this.page > Math.floor(this.total / this.size) + 1) {
        this.$warning('暂无更多数据')
        return false
      }
      this.getInnovationList()
    },
    /**
     * 看看大家的创新提案
     */
    getInnovationList() {
      let params = {
        companyId: 0,
        page: this.page,
        size: this.size
      }
      getInnovation(params)
        .then(res => {
          let { list, total } = res.data
          this.total = total
          this.proposalList = list
        })
        .catch(err => {})
    },
    toPage(path, param) {
      if (param === 'add') {
        this.$router.push({
          name: path,
          query: {
            type: param
          }
        })
      } else {
        this.$router.push({
          name: path,
          query: {}
        })
      }
    }
  },
  created() {
    this.getInnovationList()
    this.getCount()
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/channel.scss';
</style>
